import React, { useEffect, useRef, useState } from 'react';

import styles from './Button.module.scss';

import { ButtonSize, ButtonWidth } from '../../constants/ComponentEnums.jsx';
import Icon from '../FontAwesome/Icon.jsx';
import { LoaderButton } from '../Loader/LoaderButton.jsx';

// buttonCol TODO check all
const Button = ({
  id,
  loading,
  iconSide = false,
  text,
  size,
  type,
  icon,
  iconColor,
  outline = false,
  disabled,
  bold = false,
  onClick,
  width,
  textColor = '',
  border = false,
  hidden = false,
  disableHover = false,
  title,
  rounded,
}) => {
  const [isLongText, setIsLongText] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    const ratio = isMobile ? 0.9 : 0.8;
    if (textRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const buttonWidth = textRef.current.parentNode.offsetWidth;

      if (textWidth > buttonWidth * ratio) {
        setIsLongText(true);
      } else {
        setIsLongText(false);
      }
    }
  }, [text]);

  let className = [
    'btn',
    bold ? 'fw-bold' : '',
    hidden ? 'd-none' : '',
    border ? 'rounded-pill' : '',
    disableHover ? styles.noHover : '',
    !outline ? 'border-0' : '',
    rounded ? `rounded-${rounded}` : '',
  ];

  switch (size) {
    case ButtonSize.Small:
      className.push('btn-sm');
      break;
    case ButtonSize.ExtraSmall:
      className.push(styles['btn-xs']);
      break;
    case ButtonSize.Large:
      className.push('btn-lg');
      break;
    default:
      break;
  }

  switch (width) {
    case ButtonWidth.S:
      className.push(ButtonWidth.S);
      break;
    case ButtonWidth.M:
      className.push(ButtonWidth.M);
      break;
    case ButtonWidth.L:
      className.push(ButtonWidth.L);
      break;
    default:
      className.push(ButtonWidth.XL);
      break;
  }

  className = className.filter(Boolean).join(' ').trim();

  return (
    <button
      key={`button.${id}_${title}`}
      id={id}
      role={onClick === undefined ? 'none' : 'button'}
      type='button'
      className={`${className} ${styles.button} ${
        outline ? styles[`outline${type?.slice(2)}`] : styles[type]
      } d-flex align-items-center justify-content-center`}
      onClick={onClick}
      disabled={loading !== undefined ? loading : disabled}
      title={title}
    >
      {iconSide && icon && <Icon key={`icon.${id}`} icon={icon} color={styles[iconColor]} pe={2} />}
      {text && (
        <span key={`span.${id}`} ref={textRef} className={`${styles[textColor]} ${isLongText ? styles.longText : ''}`}>
          {!loading ? text : <LoaderButton />}
        </span>
      )}
      {!iconSide && icon && <Icon key={`icon2.${id}`} icon={icon} color={styles[iconColor]} px={1} />}
    </button>
  );
};

export default Button;
