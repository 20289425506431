import React from 'react';

import styles from './Button.module.scss';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BackGroundColors, ButtonSize, ButtonWidth, TextColors } from '../../constants/ComponentEnums.jsx';
import Icon from '../FontAwesome/Icon.jsx';
import { LoaderButton } from '../Loader/LoaderButton.jsx';

const SubmitButton = ({
  onClick,
  size,
  bold,
  border,
  width,
  id,
  stateName,
  objectLengthRule,
  text,
  icon,
  iconColor,
  iconSide,
}) => {
  const { t } = useTranslation();
  const isSuccess = useSelector((state) => state[stateName].isSuccess);

  let className = [
    'btn',
    isSuccess === null || objectLengthRule ? styles[BackGroundColors.Secondary] : styles[BackGroundColors.Successbg],
    bold ? 'fw-bold' : '',
    border ? 'rounded-pill' : '',
  ];

  switch (size) {
    case ButtonSize.Small:
      className.push('btn-sm');
      break;
    case ButtonSize.ExtraSmall:
      className.push(styles['btn-xs']);
      break;
    case ButtonSize.Large:
      className.push('btn-lg');
      break;
    default:
      break;
  }

  switch (width) {
    case ButtonWidth.S:
      className.push(ButtonWidth.S);
      break;
    case ButtonWidth.M:
      className.push(ButtonWidth.M);
      break;
    case ButtonWidth.L:
      className.push(ButtonWidth.L);
      break;
    default:
      className.push(ButtonWidth.XL);
      break;
  }

  className = className.filter(Boolean).join(' ').trim();

  return (
    <button
      id={id}
      type='button'
      className={`${className} d-flex align-items-center justify-content-center`}
      onClick={onClick}
      disabled={isSuccess === null || objectLengthRule}
    >
      {iconSide && icon && <Icon key={`icon.${id}`} icon={icon} color={styles[iconColor]} pe={2} />}
      <span className={TextColors.Light}>
        {objectLengthRule ? (
          t(`${text ? text : 'button.submit'}`)
        ) : isSuccess === null ? (
          <LoaderButton />
        ) : (
          t(`${text ? text : 'button.submit'}`)
        )}
        {!iconSide && icon && <Icon key={`icon2.${id}`} icon={icon} color={styles[iconColor]} px={1} />}
      </span>
    </button>
  );
};

export default SubmitButton;
