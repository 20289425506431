import React from 'react';

import { useNavigate } from 'react-router';

import Icon from '../FontAwesome/Icon.jsx';

export default function FontAwesomeIconsText({
  rounded,
  icon,
  title,
  backGroundAll = '',
  textColor = '',
  to,
  onClick,
}) {
  const navigate = useNavigate();
  const isFunc = () => {
    if (to === undefined) {
      onClick();
    } else {
      navigate(`/${to}`);
      onClick();
    }
  };
  return (
    <div
      key={`button.${title}_${to}`}
      role={'button'}
      style={{ background: '#EFE9F1', height: '40px' }}
      onClick={isFunc}
      className={`d-flex align-items-center justify-content-center ${textColor} ${backGroundAll} 
			${rounded ? `rounded-${rounded}` : ''}`}
    >
      <Icon key={`button.icon.${title}_${to}`} color={'blueviolet'} icon={icon} />
      <label
        key={`button.label.${title}_${to}`}
        role={'button'}
        className={`${backGroundAll} ms-2`}
        style={{ fontSize: '12px', color: 'blueviolet' }}
      >
        {title}
      </label>
    </div>
  );
}
