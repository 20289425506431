import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router';

import i18next from 'i18next';

import Actions from './store/redux/actions/index.js';

import refreshPage from './utils/refreshPage.js';

import { storageKeys } from './constants/Enum.jsx';
import routes from './routes.js';

export const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const lang = useSelector((state) => state.lang.language);
  const getLanguage = localStorage.getItem(storageKeys.languge);

  useEffect(() => {
    dispatch(Actions.langActions.pickLanguage(getLanguage));
    dispatch(Actions.langActions.setFlag(getLanguage));
    i18next?.changeLanguage(lang?.split(',')[0]);
  }, [dispatch, getLanguage, lang]);

  useEffect(() => {
    const hardReset = localStorage.getItem(storageKeys.hardReset);
    if (hardReset) {
      localStorage.removeItem(storageKeys.hardReset);
      refreshPage();
    }
  }, []);
  const route = routes(auth);
  const router = createBrowserRouter(route);

  return <RouterProvider router={router} />;
};

export default App;
