import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Actions from '../../store/redux/actions/index.js';

import findMyPath from '../../utils/findMyPath.js';

import useScreenSize from '../../hooks/useScreenSize.js';

const Banner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const banner = useSelector((state) => state.banner.getActiveBannerByZone);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const { isMobile, isExtraSmallMobile, isSmallMobile, isLargeMobile, isTablet } = useScreenSize();
  const hasBanner = banner?.pictureWeb !== undefined || banner?.pictureMobile !== undefined;

  useEffect(() => {
    if (lengthCurrentUserClaim && !hasBanner) {
      dispatch(Actions.bannerActions.getByZoneAction());
    }
  }, [dispatch, lengthCurrentUserClaim]);

  return (
    <div className='container-fluid sticky-top'>
      <div className='row'>
        <div className='col-12 p-0' role='button' onClick={() => navigate(findMyPath('order/package'))}>
          {!isMobile && banner?.pictureWeb && (
            <div className='d-lg-block d-none sticky-top'>
              <img
                src={banner?.pictureWeb}
                alt='pictureWeb'
                className='img-fluid p-0'
                width={'100%'}
                height={'100%'}
                style={{
                  minHeight: '70px',
                  maxHeight: '90px',
                }}
                loading='lazy'
              />
            </div>
          )}
          {!isMobile && banner?.pictureMobile && (
            <div className='d-none d-md-block d-lg-none sticky-top'>
              <img
                src={banner?.pictureMobile}
                alt='pictureMobile'
                className='img-fluid p-0'
                width={'100%'}
                height={'100%'}
                style={{
                  maxHeight: isTablet ? '70px' : '100px',
                }}
                loading='lazy'
              />
            </div>
          )}
          {isMobile && banner?.pictureMobile && (
            <div className='d-md-none d-flex sticky-top'>
              <img
                src={banner?.pictureMobile}
                alt='pictureMobile'
                className='img-fluid p-0'
                width={'100%'}
                height={'100%'}
                style={{
                  maxHeight: isExtraSmallMobile ? '100px' : isSmallMobile ? '65px' : isLargeMobile ? '65px' : '100px',
                }}
                loading='lazy'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
